const genericPaint = ['hand-painted', 'acrylic on canvas'];

const paintings = Object.freeze([
    {
      identifier: 'superman',
      title: 'Superman Departs',
      titleShort: 'Superman',
      image: 'https://s3-us-west-2.amazonaws.com/bikes-and-barbies/superman-departs.jpg',
      thumbnail: 'https://s3-us-west-2.amazonaws.com/bikes-and-barbies/superman-departs.jpg',
      attributes: ['acrylic on panel', '78" x 39"', '2025']
    },
    {
      identifier: 'polar',
      title: 'Polar Bear',
      titleShort: 'Polar',
      image: 'https://s3-us-west-2.amazonaws.com/bikes-and-barbies/polar-bear.jpg',
      thumbnail: 'https://s3-us-west-2.amazonaws.com/bikes-and-barbies/polar-bear.jpg',
      blurbs: [
          <p key={1}>Just how low can we GoPro.</p>
      ],
      attributes: [...genericPaint, '78" x 43"', '2012']
    },
    {
      identifier: 'coke',
      image: 'https://s3-us-west-2.amazonaws.com/bikes-and-barbies/coke.jpg',
      thumbnail: 'https://s3-us-west-2.amazonaws.com/bikes-and-barbies/chuck.jpg',
      title: 'Coke',
      titleShort: 'Coke',
      thumbnailPosition: 'top',
      blurbs: [
          <p key={1}>That precarious balance between heath, beauty and limp noodles.</p>
      ],
      attributes: [...genericPaint, '78" x 58"', '2007']
    },
    {
      identifier: 'cow',
      image: 'https://s3-us-west-2.amazonaws.com/bikes-and-barbies/cow.jpg',
      thumbnail: 'https://s3-us-west-2.amazonaws.com/bikes-and-barbies/cow-3d.jpg',
      title: 'Apocalypse Cow',
      titleShort: 'Cow',
      attributes: [...genericPaint, '78" x 50"', '2007']
    },
    {
      identifier: 'venus',
      image: 'https://s3-us-west-2.amazonaws.com/bikes-and-barbies/venus.jpg',
      thumbnail: 'https://s3-us-west-2.amazonaws.com/bikes-and-barbies/venus.jpg',
      title: 'The New & Improved Birth of Venus',
      titleShort: 'Venus',
      blurbs: [
          <p key={1}>The New & Improved.</p>
      ],
      thumbnailPosition: 'top',
      attributes: [...genericPaint, '78" x 39"', '2013']
  },
  {
    identifier: 'mol',
    image: 'https://s3-us-west-2.amazonaws.com/bikes-and-barbies/ski.jpg',
    title: 'MOL',
    thumbnailPosition: 'top',
    attributes: [...genericPaint, '78" x 45"', '2007']
  },
  {
    identifier: 'ken',
    image: 'https://s3-us-west-2.amazonaws.com/bikes-and-barbies/ken.jpg',
    thumbnail: 'https://s3-us-west-2.amazonaws.com/bikes-and-barbies/cleaver.jpg',
    title: 'Ken & Barbie',
    titleShort: 'Ken',
    thumbnailPosition: 'top',
    attributes: [...genericPaint, '78" x 71"', '2008']
  },
  {
    identifier: 'butter',
    thumbnail: 'https://s3-us-west-2.amazonaws.com/bikes-and-barbies/kong.jpg',
    image: 'https://s3-us-west-2.amazonaws.com/bikes-and-barbies/butter.jpg',
    titleShort: 'Butter',
    thumbnailPosition: 'top',
    title: 'Buttercups',
    attributes: [...genericPaint, '61" x 95"', '2010']
  },
  {
    identifier: 'baby',
    thumbnail: 'https://s3-us-west-2.amazonaws.com/bikes-and-barbies/baby-detail.jpg',
    image: 'https://s3-us-west-2.amazonaws.com/bikes-and-barbies/baby.jpg',
    titleShort: 'Evolve',
    title: 'You\'ve Come a Long Way Baby',
    attributes: [...genericPaint, '95" x 78"', '2005']
  },
  {
    identifier: 'shoot',
    image: 'https://s3-us-west-2.amazonaws.com/bikes-and-barbies/shoot.jpg',
    thumbnail: 'https://s3-us-west-2.amazonaws.com/bikes-and-barbies/super.jpg',
    titleShort: 'Shoot',
    title: 'Shoot the Messenger',
    attributes: [...genericPaint, '78" x 52"', '2006']
  },
  {
    identifier: 'birds',
    image: 'https://s3-us-west-2.amazonaws.com/bikes-and-barbies/birds.jpg',
    title: 'The Birds',
    titleShort: 'Birds',
    attributes: [...genericPaint, '63" x 95"', '2005']
  },
]);

export const other = Object.freeze([
  {
    identifier: 'canis-lupus-stinkus',
    image: 'https://s3-us-west-2.amazonaws.com/bikes-and-barbies/canis-lupus-stinkus.jpg',
    title: 'Canis Lupus Stinkus',
    titleShort: "Stinkus",
    attributes: ['acrylic on panel', '30" x 36"', '2025']
  },
  {
    identifier: 'lemon-head',
    image: 'https://s3-us-west-2.amazonaws.com/bikes-and-barbies/lemon-head.jpg',
    title: 'Lemon Brain',
    titleShort: "Lemon",
    attributes: ['acrylic on panel', '12" x 9"', '2025']
  },
  {
    identifier: 'lime-head',
    image: 'https://s3-us-west-2.amazonaws.com/bikes-and-barbies/lime-head.jpg',
    title: 'Lime Brain',
    titleShort: 'Lime',
    attributes: ['acrylic on panel', '12" x 9"', '2025']
  },
  {
    identifier: 'queen-mike',
    image: 'https://s3-us-west-2.amazonaws.com/bikes-and-barbies/queen-mike.jpg',
    title: 'Queen Mike',
    titleShort: 'Queen Mike',
    attributes: ['acrylic on panel', '16" x 20"', '2025']
  },
  {
    identifier: 'os',
    image: 'https://s3-us-west-2.amazonaws.com/bikes-and-barbies/the-os.png',
    title: 'The OS',
    titleShort: "The OS",
    attributes: ['acrylic on panel', '20" x 16"', '2024']
  },
  {
    identifier: 'dj',
    image: 'https://s3-us-west-2.amazonaws.com/bikes-and-barbies/don-julio-buns.png',
    title: 'Cinnamon Buns',
    titleShort: "Buns",
    attributes: ['acrylic on panel', '16" x 20"', '2023']
  },
  {
    identifier: 'truck',
    image: 'https://s3-us-west-2.amazonaws.com/bikes-and-barbies/truck.jpg',
    title: 'Rusty Trucks',
    titleShort: "Rusty",
    attributes: ['acrylic on panel', '20" x 16"', '2017']
  },
  {
    identifier: 'bike-print',
    image: 'https://s3-us-west-2.amazonaws.com/bikes-and-barbies/bikePrint.jpg',
    title: 'Bike!',
    titleShort: "Bike!",
    attributes: ['screenprint', '16" x 23"', '2003'],
    thumbnailPosition: 'top',
  },
  {
    identifier: 'numbers-print',
    image: 'https://s3-us-west-2.amazonaws.com/bikes-and-barbies/numbersPrint.jpg',
    title: 'untitled',
    titleShort: "untitled",
    attributes: ['screenprint', '16" x 23"', '2003'],
    thumbnailPosition: 'bottom',
  },
]);
  
  export default paintings;